/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Box, Flex, BoxProps, Link } from 'rebass';

import { P, H4, Text } from '../../components/Text';
import { AboutLayout } from '../../layouts/AboutLayout';
import { Container } from '../../components/Container';
import Logo from '../../components/Logo';
import { ISDCLogo } from '../../components/IXDALogoBox/ISDCLogo';
import { IDESLogo } from '../../components/IXDALogoBox/IDESLogo';
import { AwardsLogo } from '../../components/IXDALogoBox/AwardsLogo';

type AboutBandProps = {
  logo: React.ReactNode;
} & BoxProps;

const AboutBand: React.FC<AboutBandProps> = ({ logo, children, ...props }) => (
  <Box sx={{ py: [5, 6] }} {...props}>
    <Container>
      <Flex mx={-2} flexDirection={['column', 'row']}>
        <Flex px={2} mb={[4, 0]} flex={2} alignItems={['center']}>
          {logo}
        </Flex>
        <Flex px={2} flex={3} alignItems={['center']}>
          <Box>{children}</Box>
        </Flex>
      </Flex>
    </Container>
  </Box>
);

const AboutOverviewPage = () => {
  return (
    <AboutLayout>
      <Container>
        <Box
          sx={{
            width: [null, '80%'],
            mx: 'auto',
            my: [4],
            textAlign: 'center',
          }}
        >
          <P sx={{ fontSize: 2 }}>
            Interaction Week is a series of design events for the global
            interaction design community.
          </P>
          <P sx={{ fontSize: 2 }}>This year’s edition features:</P>
        </Box>
      </Container>

      <AboutBand
        sx={{ bg: 'text', color: 'background' }}
        logo={
          <Logo
            type="interaction-21glitch"
            fill="background"
            sx={{ height: ['30px', '60px'], width: ['115px', '230px'] }}
          />
        }
      >
        <H4>Interaction 21</H4>
        <Text sx={{ marginTop: 3 }}>
          Our key focus. Join the brightest minds in Interaction design as we
          examine the perils that surround us.
        </Text>
      </AboutBand>

      <AboutBand logo={<IDESLogo fill="text" height={['50px', '100px']} />}>
        <H4>Interaction Design Education Summit</H4>
        <Text sx={{ my: 3 }}>
          Each year, we build on what we’ve learned from previous summits while
          maintaining our focus on advancing interaction design education across
          all levels of the career development path from early education to
          professional learning.
        </Text>
        <Link href="https://www.edusummit.ixda.org/" target="_blank">
          Learn more ↗
        </Link>
      </AboutBand>
      <AboutBand
        sx={{ bg: 'text', color: 'background' }}
        logo={<ISDCLogo fill="background" height={['50px', '100px']} />}
      >
        <H4>Student Design Charette</H4>
        <Text sx={{ my: 3 }}>
          The Student Design Charette (SDC) champions the next generation of
          interaction design. We exist to promote design for good by new
          designers. To do this we bring together students &amp; early
          professionals to tackle big challenges.
        </Text>
        <Link
          href="http://sdc.ixda.org/"
          sx={{ color: 'background' }}
          target="_blank"
        >
          Learn more ↗
        </Link>
      </AboutBand>
      <AboutBand logo={<AwardsLogo fill="text" height={['50px', '100px']} />}>
        <H4>Interaction Awards 2021</H4>
        <Text sx={{ my: 3 }}>
          Celebrate with us as we recognize some of the brightest minds in
          interaction design from around the world. The annual Interaction
          Awards ceremony format during Interaction 21 will be revealed in the
          coming weeks.
        </Text>
        <Link href="https://awards.ixda.org/" target="_blank">
          Learn more ↗
        </Link>
      </AboutBand>

      {/* To remove the margin top from the footer so it's nicer. */}
      <Box mb={-5} />
    </AboutLayout>
  );
};

export default AboutOverviewPage;
